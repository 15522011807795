<template>
	<!--伦理上会 手机端投票-->
	<div class="ethical-vote-wrap">
		<Spin fix v-if="loading"></Spin>
		<div class="vote-con">
			<van-tabs
				swipeable
				animated
				sticky
				v-if="projectList.length"
				v-model="tabName"
				@change="onClickTab"
			>
				<van-tab
					v-for="(item, index) in projectList"
					:name="`project_${item}`"
					:title="`项目${index + 1}`"
					:key="item"
				>
					<van-form @submit="handleSubmit" v-if="tabName === `project_${item}`">
						<van-cell>
							<div>
								项目名称：{{ proVoteObj.projectName || "-" }}
								<van-tag type="success" v-if="proVoteObj.voteIngStatus === 1"
									>进行中</van-tag
								>
								<van-tag
									color="#9e9e9e"
									v-else-if="proVoteObj.voteIngStatus === 2"
									>已结束</van-tag
								>
								<van-tag type="danger" v-else>未开始</van-tag>
							</div>
						</van-cell>
						<van-cell
							:value="`主要研究者：${proVoteObj.pis || '-'}`"
						></van-cell>
						<van-cell-group
							:title="item.name"
							v-for="(item, index) in proVoteObj.votes"
							:key="`vote_${index}`"
						>
							<van-cell class="cell-radio-wrap">
								<van-radio-group
									v-model="item.checkedId"
									@change="handleChangeRadio"
								>
									<van-radio
										:name="child.optionId"
										checked-color="#2D8cF0"
										:disabled="
											proVoteObj.voteIngStatus !== 1 || proVoteObj.isVote
										"
										v-for="(child, childIndex) in item.options"
										:key="`vote_${child.optionId}`"
									>
										<div class="title-wrap">
											<span>{{ child.name }}</span>
											<span
												v-if="proVoteObj.voteIngStatus === 2"
												style="color: #969799"
												>{{ child.checkedNum }}票
												{{ child.percent || 0 }}%</span
											>
										</div>
										<div
											v-if="proVoteObj.voteIngStatus === 2"
											style="font-size: 12px; color: #969799"
										>
											{{ child.checkedUsers }}
										</div>
									</van-radio>
								</van-radio-group>
							</van-cell>
						</van-cell-group>
						<div style="margin-top: 50px" v-if="proVoteObj.voteIngStatus === 1">
							<van-button
								round
								block
								color="#ff5722"
								:disabled="!isCanSubmit || proVoteObj.isVote"
								native-type="submit"
							>
								{{ proVoteObj.isVote ? "已投票" : "提交" }}
							</van-button>
						</div>
					</van-form>
				</van-tab>
			</van-tabs>
			<div
				v-else
				style="
					margin-top: 120px;
					text-align: center;
					font-size: 16px;
					color: #ff9800;
					font-weight: 700;
				"
			>
				该会议没有设置上会项目 ！
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api/ethicalMeeting/index"
import {
	Button,
	Field,
	Form,
	Dialog,
	Toast,
	RadioGroup,
	Radio,
	Tab,
	Tabs,
	Tag,
	Cell,
	CellGroup
} from "vant"
import "vant/lib/picker/style"
import "vant/lib/field/style"
import "vant/lib/popup/style"
import "vant/lib/button/style"
import "vant/lib/form/style"
import "vant/lib/dialog/style"
import "vant/lib/calendar/style"
import "vant/lib/radio-group/style"
import "vant/lib/radio/style"
import "vant/lib/tab/style"
import "vant/lib/tabs/style"
import "vant/lib/tag/style"
import "vant/lib/cell/style"
import "vant/lib/cell-group/style"

const { mobileProVoteDetail, ethicalMobileLoginStateCheck, ethicalMobileVote } =
	api

export default {
	name: "ethicalMobileMeetVote",
	components: {
		VanButton: Button,
		VanField: Field,
		VanForm: Form,
		VanRadioGroup: RadioGroup,
		VanRadio: Radio,
		VanTab: Tab,
		VanTabs: Tabs,
		VanTag: Tag,
		VanCell: Cell,
		VanCellGroup: CellGroup
	},
	data() {
		return {
			loading: false,
			time: null,
			tabName: "",
			proVoteObj: {},
			ethicMeetingProjectId: "",
			checkedItems: []
		}
	},
	computed: {
		voteObj() {
			const str = sessionStorage.getItem("mobileVoteInfo")
			return str ? JSON.parse(str) : {}
		},
		projectList() {
			return this.voteObj.ethicMeetingProjectIds || []
		},
		// 是否可以提交
		isCanSubmit() {
			if (this.checkedItems && this.proVoteObj.votes) {
				return !!(this.checkedItems.length === this.proVoteObj.votes.length)
			}
			return false
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.tabName = `project_${this.projectList[0]}`
			this.init(this.projectList[0])
			this.refreshState()
		})
	},
	methods: {
		async init(projectId) {
			this.ethicMeetingProjectId = projectId
			if (!this.projectList.length) {
				Toast.fail("该会议没有设置上会项目！")
				return
			}
			this.loading = true
			const res = await mobileProVoteDetail({
				ethicMeetingId: this.$route.query.meetId,
				ethicMeetingProjectId: projectId,
				token: this.voteObj.token
			})
			if (res) {
				this.proVoteObj = {
					...res.data,
					votes: res.data.votes.map(item => {
						let newItem = { ...item }
						if (item.options) {
							const checkedOption = item.options.find(child => child.isChecked)
							checkedOption &&
								(newItem = { ...newItem, checkedId: checkedOption.optionId })
						}
						return newItem
					})
				}
			}
			this.loading = false
		},
		// 点击标签页
		onClickTab(val) {
			this.checkedItems = []
			const id = val.split("_")[1]
			this.init(id)
		},
		// 点击选项
		handleChangeRadio(val) {
			this.checkedItems = this.proVoteObj.votes.filter(item => item.checkedId)
		},
		// 定时器每隔5秒刷新一次 登录状态
		refreshState() {
			this.time && window.clearInterval(this.time)
			this.time = setInterval(() => {
				this.checkState()
			}, 5000)
		},
		checkState() {
			this.$asyncDo(async () => {
				const res = await ethicalMobileLoginStateCheck({
					token: this.voteObj.token
				})
				if (!res) {
					this.time && window.clearInterval(this.time)
					this.$router.push({
						name: "mobileEthicalLogin",
						query: {
							meetId: this.$route.query.meetId
						}
					})
				}
			})
		},
		handleSubmit(values) {
			Dialog.confirm({
				title: "提示",
				message: "提交后不可修改，是否确认提交投票？",
				confirmButtonColor: "#2D8cF0"
			})
				.then(async () => {
					this.loading = true
					const res = await ethicalMobileVote({
						ethicMeetingProjectId: this.ethicMeetingProjectId,
						optionIds: this.proVoteObj.votes.map(item => item.checkedId),
						token: this.voteObj.token
					})
					if (res) {
						Toast.success("投票成功")
						this.init(this.ethicMeetingProjectId)
					}
					this.loading = false
				})
				.catch(() => {
					// on cancel
				})
		}
	},
	beforeDestroy() {
		this.time && window.clearInterval(this.time)
	}
}
</script>

<style lang="less">
body {
	min-width: 0;
}
.ethical-vote-wrap {
	position: relative;
	z-index: 2;
	overflow: auto;
	width: 100vw;
	height: 100vh;
	background: #fefdf8 url("../../../assets/mobileimages/bg-sign.png") no-repeat;
	background-size: 100% 100%;
	.vote-con {
		padding: 20px 25px 80px;
	}
	.van-field__label {
		width: 7em;
	}
	/*.van-cell__value {*/
	/*text-align: left;*/
	/*}*/
	.van-picker__confirm {
		color: #2d8cf0;
	}
	.van-button--primary {
		//background: linear-gradient(to right, #fe834b, #fda21e);
		background: #1c2730;
		border-color: #2d8cf0;
	}
	.van-field--error .van-field__control::placeholder {
		color: #c5c8ce;
	}
	.cell-radio-wrap {
		.van-radio {
			padding: 5px 0;
			align-items: baseline;
			border-bottom: 1px dashed #eee;
		}
		.van-radio__icon {
			flex-basis: 16px;
			font-size: 16px;
			transform: translate(0, 2px);
		}
		.van-radio__label {
			flex-grow: 1;
		}
		.title-wrap {
			font-size: 14px;
			line-height: 28px;
			display: flex;
			justify-content: space-between;
		}
	}
}
</style>
